<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <!--        <a-form-item label="认证状态">-->
        <!--          <a-select allowClear  v-model:value="searchForm.customerName.value"-->
        <!--                     :options="customerNameEnum" placeholder="请选择认证状态"-->
        <!--                      style="width:140px"/>-->
        <!--        </a-form-item>-->
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.customerType"
            :options="userSubjectOpt" placeholder="小程序主体" style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.promoterType.value"
            :options="$store.state.enumAll.extensionTypeEnum" placeholder="推广类型" style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.mobile" placeholder="电话号码"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.nickName" placeholder="昵称"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.promoter" placeholder="推广人"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.salesman" placeholder="业务员"/>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.channelName" placeholder="渠道"/>
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-space>
        <a-button :disabled="selectedRowKeys.length < 1" @click="distributionShow = true, bindingType = 0">
          绑定业务员
        </a-button>
        <a-button :disabled="couponDisabled" @click="setCouponShow = true">
          优惠券
        </a-button>
        <a-button :disabled="couponDisabled" @click="inquireFrequencyShow = true">
          增加询价次数
        </a-button>
      </a-space>
    </template>
    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio' }" :loading="loading"
      @change="handleTableChange">
      <template #promoterType="{ record }">
        <a-space>
          <div v-if="record.promoterType != null"> {{ record.promoterType.label }} {{ record.promoterConfigName }}</div>
          <a v-if="record.promoterType === null && record.promoter === ''"
            @click="promoterTypeShow = true, id = record.id">配置</a>
          <!--          <div class="edit" v-if="record.promoterType.value===3" @click="promoterTypeShow = true, id=record.id">[ 修改 ]</div>-->
        </a-space>
      </template>
      <!-- <template #headPortrait="{ record }">
        <a-image :width="30" :height="30" v-if="record.headPortrait!=null" :src="record.headPortrait" :preview="record.headPortrait" />
      </template> -->
      <template #salesman="{ record }">
        <a-space>
          {{ record.salesman }}
          <a v-if="record.salesman === ''" @click="distributionShow = true, bindingType = 1, id = record.id">未绑定</a>
          <div class="edit" v-if="record.salesman !== '' && record.promoterType && record.promoterType.value === 3"
            @click="distributionShow = true, id = record.id">[ 修改 ]</div>
        </a-space>
      </template>
      <!-- <template #customerName="{ record }">

        <a-space>
          {{record.customerName}}
          <a v-if="record.customerName===''&& (record.promoterType===null || record.promoterType.value!== 1 )" @click="customerNameShow = true , id=record.id">
            <div>未认证</div>
          </a>
          <div class="edit" v-if="record.customerName!==''&&record.promoterType&&record.promoterType.value===3" @click="customerNameShow = true , id=record.id">[ 修改 ]</div>
        </a-space>
      </template> -->
      <template #operation="{ record }">
        <div class="edit" @click="detailShow = true, id = record.id">查看明细</div>
      </template>
      <template #black="{ record }">
        <a-popconfirm :title="`你确定${record.isBlack ? '取消' : '确认'}拉黑吗？`" @confirm="onIsBlack(record)">
          <a-switch v-model:checked="record.isBlack" @click="record.isBlack = !record.isBlack" />
        </a-popconfirm>
      </template>
    </a-table>

    <a-drawer title="" placement="right" :closable="false" width='80%' v-model:visible="detailShow">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane :key="1" tab="订单列表">
          <Order v-if="detailShow && activeKey === 1" :userId="id"></Order>
        </a-tab-pane>
        <a-tab-pane :key="2" tab="推广列表" force-render>
          <Record v-if="detailShow && activeKey === 2" :userId="id" />
        </a-tab-pane>
        <a-tab-pane :key="3" tab="询价列表" force-render>
          <InquiryTable v-if="detailShow && activeKey === 3" v-model:appletUserId="id" />
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
    <!-- 优惠券 -->
    <a-modal v-model:visible="setCouponShow" title="分发优惠券" @ok="setCounpon" @cancel="setCouponShow = false">
      <div>
        <a-form :model="couponForm" ref="couponFormRef" labelAlign="right" :rules="couponRules">
          <a-form-item label="优惠券名称" name="name">
            <a-input v-model:value="couponForm.name" />
          </a-form-item>
          <a-form-item label="优惠券金额" name="amt">
            <a-input v-model:value="couponForm.amt" />
          </a-form-item>
          <a-form-item label="开始时间" name="startTimeString">
            <a-date-picker v-model:value="couponForm.startTimeString" :disabled-date="startDateDis" format="YYYY-MM-DD"
              @change="startDateChange" />
          </a-form-item>
          <a-form-item label="到期时间" name="endTimeString">
            <a-date-picker v-model:value="couponForm.endTimeString" :disabled-date="endDateDis" format="YYYY-MM-DD"
              @change="endDateChange" />
          </a-form-item>
          <a-form-item label="优惠券说明" name="remark">
            <a-textarea v-model:value="couponForm.remark" placeholder="填写备注" :maxlength="400" showCount :rows="4" />
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
    <a-modal @cancel="onDistributionShowNo" v-model:visible="distributionShow" width="40%" title="绑定业务员"
      @ok="onDistributionOk">
      <a-select v-model:value="distributionForm.name" class="select" show-search :filter-option="false"
        :not-found-content="fetching ? '起码输入2个中文进行搜索' : '没有相关数据'" @select="onDistributionCustomer" @search="handleSearch"
        @change="onEmpIdChange">
        <a-select-option v-for="item  in employeeArray" :value="item.name" :key="item.id">
          <a-row>
            <a-col :span="8">
              {{ item.name }}
            </a-col>
            <a-col :span="8">
              {{ item.orgName }}
            </a-col>
            <a-col :span="8">
              {{ item.mobile }}
            </a-col>
          </a-row>
        </a-select-option>
      </a-select>
    </a-modal>
    <a-modal v-if="customerNameShow" v-model:visible="customerNameShow" width="40%" title="企业认证" @ok="onCustomerNameOk"
      @cancel="onCustomerNameNo">
      <a-form ref="formRef" :rules="rules" :model="customerNameForm" layout="vertical">
        <a-form-item label="企业名称" name="key">
          <a-select v-model:value="customerNameForm.key" placeholder="起码2个字开始搜索" style="width: 100%"
            :filter-option="false" :show-arrow="false" size="large" @search="customerNameSearch"
            @change="onCustomerChange" show-search>
            <a-select-option v-for="item in customerNames" :key="item.customerId" :value="item.customerId">
              <span>{{ item.name }}</span>
              <span style="float:right;">{{ item.mobile }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-if="promoterTypeShow" v-model:visible="promoterTypeShow" width="40%" title="推广人配置" @ok="onPromoterTypeOk"
      @cancel="onPromoterTypeNo">
      <a-form ref="promoterTypeFormRef" :rules="promoterTypeRules" :model="promoterTypeForm" layout="vertical">
        <a-form-item label="选择类型" :name="['promoterType', 'value']">
          <a-select v-model:value="promoterTypeForm.promoterType.value" :options="extensionTypeEnum"
            placeholder="请选择推广类型" />
        </a-form-item>
        <a-form-item label="推广人姓名" v-if="promoterTypeForm.promoterType.value === 1" name="name">
          <a-select @change="onEmpIdChange" v-model:value="promoterTypeForm.name" class="select" show-search
            :filter-option="false" :not-found-content="fetching ? '起码输入2个中文进行搜索' : '没有相关数据'"
            @select="onDistributionCustomer" @search="handleSearch">
            <a-select-option v-for="item  in employeeArray" :value="item.name" :key="item.id">
              <a-row>
                <a-col :span="8">
                  {{ item.name }}
                </a-col>
                <a-col :span="8">
                  {{ item.orgName }}
                </a-col>
                <a-col :span="8">
                  {{ item.mobile }}
                </a-col>
              </a-row>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="推广人姓名" v-if="promoterTypeForm.promoterType.value === 2" name="name">
          <a-input v-model:value="promoterTypeForm.name" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal v-model:visible="inquireFrequencyShow" title="增加询价次数" @ok="confirmAddNum" :confirmLoading="loading">
      <div style="text-align: center;">
        <a-input-number v-model:value="addNum" style="width:100%" :min="1" :max="21" />
        <span style="color:#f00;font-size:12px">询价增加的次数请控制在20以内</span>
      </div>
    </a-modal>
  </HxTable>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import moment from 'moment'
import HxTable from '@/components/HxTable'
import { page, configCustomer, configPromoter, configSalesman, userIsBlack, giveCoupon, addInquiryNum } from '@/api/crmManagement/crmWechatUser'
import { message } from 'ant-design-vue'
import { list } from '@/api/system/employee'
// import { customerName as customerNameSee } from '@/api/system/setting'
import { getPageList } from '@/api/crmManagement/cooperativeCustomers'
import Record from '@/views/BEnd/extension/record.vue'
import Order from './order'
import InquiryTable from '@/views/crmManagement/crmInquiryList/table.vue'
export default {
  components: {
    HxTable,
    Record,
    Order,
    InquiryTable
  },
  setup () {
    const formRef = ref()
    const promoterTypeFormRef = ref()
    const couponFormRef = ref(null)
    const state = reactive({
      id: '',
      empId: '',
      activeKey: 1,
      addNum: null,
      licenseFile: [],
      setCouponShow: false,
      couponDisabled: true,
      licenseLoading: false,
      detailShow: false,
      inquireFrequencyShow: false,
      customerNames: [],
      fetching: true,
      bindingType: 0,
      distributionShow: false,
      customerNameShow: false,
      promoterTypeShow: false,
      loading: false,
      listData: [],
      employeeArray: [],
      searchForm: {
        promoterType: {},
        customerName: {},
        customerType:null
      },
      couponForm: {
        name: null,
        amt: null,
        endTimeString: null,
        startTimeString: null,
        remark: ''
      },
      distributionForm: {},
      customerNameForm: {},
      promoterTypeForm: {
        promoterType: {}
      },
      selectedRowKeys: [],
      userSubjectOpt:[
        {
          label: '微信小程序',
          value: 2
        },
        {
          label: '抖音小程序',
          value: 12
        }
      ],
      columns: [
        {
          title: '昵称',
          dataIndex: 'nickName',
          align: 'center'
        },
        // {
        //   title: '头像',
        //   dataIndex: 'headPortrait',
        //   align: 'center',
        //   slots: {
        //     customRender: 'headPortrait'
        //   }
        // },
        {
          title: '电话号码',
          dataIndex: 'mobile',
          align: 'center'
        },
        {
          title: '注册时间',
          dataIndex: 'regTime',
          align: 'center'
        },
        {
          title: '小程序主体',
          dataIndex: 'customerType.label',
          align: 'center'
        },
        {
          title: '上级推广人',
          dataIndex: 'promoter',
          align: 'center'
        },
        {
          title: '推广类型',
          dataIndex: 'promoterType',
          align: 'center',
          slots: {
            customRender: 'promoterType'
          }
        },
        {
          title: '推广人数',
          dataIndex: 'promoterNum',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center',
          slots: {
            customRender: 'salesman'
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customerName',
          align: 'center'
        },
        {
          title: '渠道来源',
          dataIndex: 'channelName',
          align: 'center'
        },
        {
          title: '是否拉黑',
          dataIndex: 'black',
          align: 'center',
          slots: {
            customRender: 'black'
          }
        },
        {
          title: '操作',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        key: {
          required: true,
          message: '请填写承运商名称',
          trigger: 'blur'
        }
      },
      couponRules: {
        name: [{
          required: true,
          message: '请填写优惠券名称',
          trigger: 'blur'
        }],
        amt: [{
          required: true,
          message: '请填写优惠券金额',
          trigger: 'blur'
        }, {
          pattern: /^\+?[1-9][0-9]*$/,
          message: '金额为大于0的整数',
          trigger: 'blur'
        }],
        startTimeString: [{
          required: true,
          message: '请选择开始时间',
          trigger: 'blur'
        }],
        endTimeString: [{
          required: true,
          message: '请选择到期时间',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请填写优惠券说明',
          trigger: 'blur'
        }, {
          pattern: /^(?=([\u4e00-\u9fa5].*){10})/,
          message: '请填写正确的说明(不少于10个字符的汉字)',
          trigger: 'blur'
        }]
      },
      promoterTypeRules: {
        promoterType: { value: [{ required: true, message: '请选择推广类型', trigger: 'change', type: 'number' }] },
        name: {
          required: true,
          message: '请填写推广人姓名',
          trigger: 'blur'
        }
      },
      customerNameEnum: [
        {
          label: '全部',
          value: ''
        },
        {
          label: '已认证',
          value: 1
        },
        {
          label: '未认证',
          value: 0
        }
      ],
      // 推广类型
      extensionTypeEnum: [
        {
          label: '内部员工',
          value: 1
        }
      ]
    })

    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {
        promoterType: {}
      }
      loadData()
    }
    onMounted(() => {
      loadData()
    })
    const onSelectChange = selectedRowKeys => {
      state.couponDisabled = false
      state.id = selectedRowKeys[0]
      state.selectedRowKeys = selectedRowKeys
    }

    // 用户搜索
    const handleSearch = value => {
      // var reg = new RegExp('[\u4E00-\u9FA5]+')
      // if (reg.test(value) && value.length > 1) {
      if (value.length > 1) {
        state.fetching = false
        shake(state, value)
      }
    }
    const shake = (_that, val) => {
      if (_that.timer !== null) {
        clearTimeout(_that.timer)
      }
      _that.timer = setTimeout(() => {
        list({ name: val }).then((res) => {
          if (res.code === 10000) {
            state.employeeArray = res.data
            state.fetching = true
          }
        })
      }, 500)
    }
    // 选择用户 赋值
    const onDistributionCustomer = (value, option) => {
      state.distributionForm.empAfterId = option.key
      state.distributionForm.name = value
    }
    // 绑定业务员
    const onDistributionOk = () => {
      configSalesman(
        {
          salesman: state.distributionForm.name,
          salesmanId: state.empId,
          id: state.id
        }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          onDistributionShowNo()
          loadData()
        }
      })
    }
    // 取消绑定业务员
    const onDistributionShowNo = () => {
      state.distributionShow = false
      state.distributionForm.name = ''
      state.employeeArray = []
      state.id = ''
      state.empId = ''
      state.selectedRowKeys = []
    }
    // 获取企业客户
    const customerNameSearch = async val => {
      if (val.length > 1) {
        if (state.timeOut !== null) {
          clearTimeout(state.timeOut)
        }
        state.timeOut = setTimeout(() => {
          getPageList({ name: val, type: 1 }).then(res => {
            if (res.code === 10000) { state.customerNames = res.data.records } else state.customerNames = []
          })
        }, 500)
      } else {
        state.customerNames = []
      }
    }
    // 认证企业
    const onCustomerNameOk = () => {
      formRef.value.validate().then(() => {
        configCustomer(
          {
            customerId: state.customerNameForm.key,
            customerName: state.customerNameForm.customerId,
            id: state.id
          }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onCustomerNameNo()
            loadData()
          }
        })
      })
    }
    // 认证企业关闭
    const onCustomerNameNo = () => {
      state.customerNameShow = false
      state.customerNameForm = {}
      state.id = ''
    }
    // 企业选择事件
    const onCustomerChange = (e, v) => {
      state.customerNameForm = v
      state.customerNameForm.customerId = v.children[0].children
    }
    // 员工被选择
    const onEmpIdChange = (e, v) => {
      state.empId = v.key
    }
    // 配置推广人
    const onPromoterTypeOk = () => {
      promoterTypeFormRef.value.validate().then(() => {
        configPromoter(
          {
            promoterType: state.promoterTypeForm.promoterType,
            name: state.promoterTypeForm.name,
            empId: state.empId,
            id: state.id
          }).then(res => {
          if (res.code === 10000) {
            message.success(res.msg)
            onPromoterTypeNo()
            loadData()
          }
        })
      })
    }
    // 取消推广人
    const onPromoterTypeNo = () => {
      state.promoterTypeShow = false
      state.promoterTypeForm.promoterType.value = ''
      state.promoterTypeForm.name = ''
      state.employeeArray = []
      state.id = ''
      state.empId = ''
    }
    // 取消推广人
    const onIsBlack = (e) => {
      e.isBlack = !e.isBlack
      if (e.isBlack) {
        e.black = 1
      } else {
        e.black = 0
      }
      userIsBlack({
        id: e.id,
        isBlack: e.black
      }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      })
    }
    const startDateDis = current => {
      return current && current <= moment().startOf('day')
    }
    const endDateDis = current => {
      return current && current <= moment().endOf('day')
    }
    const endDateChange = (e, v) => {
      state.couponForm.endTimeString = v
    }
    const startDateChange = (e, v) => {
      state.couponForm.startTimeString = v
    }
    const setCounpon = () => {
      couponFormRef.value.validate()
        .then(() => {
          giveCoupon({ ...state.couponForm, appletUserId: state.id }).then(res => {
            if (res.code === 10000) {
              message.success('成功')
              state.couponForm = {
                amt: 0,
                endTimeString: null,
                remark: ''
              }
              state.setCouponShow = false
            }
          })
        }).catch(err => { console.log(err) })
    }
    const confirmAddNum = () => {
      state.loading = true
      addInquiryNum({
        appletUserId: state.id,
        addNum: state.addNum
      }).then(res=>{
        if(res.code===10000){
          message.success('添加成功')
          state.inquireFrequencyShow = false
          state.addNum = null
        }
      }).finally(()=>{state.loading=false})
    }
    return {
      ...toRefs(state),
      couponFormRef,
      reset,
      startDateDis,
      endDateDis,
      onSearch,
      loadData,
      setCounpon,
      confirmAddNum,
      endDateChange,
      startDateChange,
      handleTableChange,
      onSelectChange,
      handleSearch,
      onDistributionCustomer,
      onDistributionOk,
      onDistributionShowNo,
      onCustomerNameOk,
      customerNameSearch,
      onPromoterTypeOk,
      onPromoterTypeNo,
      onCustomerChange,
      onCustomerNameNo,
      onEmpIdChange,
      formRef,
      promoterTypeFormRef,
      onIsBlack
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
